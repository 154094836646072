<template>
	<div>
		<ktv-table
			id="listpd"
			:columns="columns"
			:is-loading="isLoading"
			:rows="rows"
			:total-rows="totalRecords"
			:mode="modeTable"
			:filter="false"
			:search-custom-enabled="false"
			:hidden-header="true"
			:rows-per-page="serverParams.rowPerpage"
			search-placeholder="Search by Product Name"
			@on-column-filter="onColumnFilter"
			@on-page-change="onPageChange"
			@on-per-page-change="onPerPageChange"
			@on-search="onSearchFilter"
			@on-sort-change="onSortChange"
		>
			<template #rows="{ props }">
				<span v-if="props.column.field == 'DateCreated'">
					{{ formatDate(props.row.DateCreated) }}
				</span>
			</template>
		</ktv-table>
	</div>
</template>
<script>
	import { KtvTable } from "@/components"
	export default {
		components: {
			KtvTable
		},
		props: {
			email: {
				default: "",
				required: true,
				type: String,
			}
		},
		data() {
			return {
				modalsReg: false,
				isLoading: false,
				modeTable: "remote",
				columns: [
					{
						label: "ID Product",
						field: "ProductID",
						thClass: "text-center",
					},
					{
						label: "Product Name",
						field: "Name",
						thClass: "text-center",
					},
					{
						label: "Product Category",
						field: "ProductCategoryName",
						thClass: "text-center",
					},
					{
						label: "Stock Qty",
						field: "Stock",
						thClass: "text-center",
						tdClass: "text-center",
					},
					{
						label: "Price",
						field: "BasePrice",
						thClass: "text-center",
						tdClass: "text-right",
					},
					{
						label: "Registered",
						field: "DateCreated",
						thClass: "text-center",
						tdClass: "text-center",
					}
				],
				rows: [],
				totalRecords: 0,
				serverParams: {
					columnFilters: {
						Email: "nannisa061@gmail.com"
					},
					sort: [{
						field: "",
						type: ""
					}],
					currentPage: 1,
					rowPerpage: 10,
				},
			}
		},
		watch: {
			email: {
				handler(val) {
					if (val !== "") {
						this.updateParams({
							columnFilters: { Email: val },
						})
						this.loadItems()
					}
				},
				deep: true,
				immediate: true,
			},
		},
		// mounted() {
		// 	this.loadItems()
		// },
		methods: {
			updateParams(newProps) {
				this.serverParams = Object.assign({}, this.serverParams, newProps)
			},
			onPageChange(params) {
				this.updateParams({ currentPage: params.currentPage })
				this.loadItems()
			},
			onPerPageChange(params) {
				this.updateParams({ rowPerpage: params.currentPerPage })
				this.loadItems()
			},
			onSortChange(params) {
				this.updateParams({
					sort: [
						{
							type: params[0].type,
							field: params[0].field,
						},
					],
					currentPage: 1
				})
				this.loadItems()
			},
			onColumnFilter(params) {
				this.updateParams(params)
				this.loadItems()
			},
			onSearchFilter(event) {
				this.updateParams({
					columnFilters: { Email: this.email, Name: event },
				})
				this.loadItems()
			},
			loadItems() {
				this.isLoading = true
				this.$http
					.Post(this.serverParams, this.$urlApi.sme.listProductSme)
					.then((response) => {
						this.isLoading = false
						if (response.success) {
							this.rows = response.results.data
							this.totalRecords = response.results.total
						} else {
							this.$swal("Error!", response.error_massage, "error")
						}
					})
					.catch((error) => {
						this.isLoading = false
						this.$swal("Error!", error.response.error_massage, "error")
					})
			},
		}
	}
</script>