<template>
	<div>
		<ktv-table
			id="listtrans"
			:columns="columns"
			:is-loading="isLoading"
			:rows="rows"
			:total-rows="totalRecords"
			:mode="modeTable"
			:filter="true"
			:search-custom-enabled="false"
			:rows-per-page="serverParams.rowPerpage"
			search-placeholder="Search by Buyer Name"
			@on-column-filter="onColumnFilter"
			@on-page-change="onPageChange"
			@on-per-page-change="onPerPageChange"
			@on-search="onSearchFilter"
			@on-sort-change="onSortChange"
			@on-open-filter="openFilter"
		>
			<template #rows="{ props }">
				<span v-if="props.column.field == 'DateCreated'">
					{{ formatDate(props.row.DateCreated) }}
				</span>
				<span v-else-if="props.column.field == 'Price'">
					{{ numberWithCommas(Number(props.row.Price)) }}
				</span>
				<span v-else-if="props.column.field == 'Discount'">
					{{ numberWithCommas(Number(props.row.Discount)) }}
				</span>
				<span v-else-if="props.column.field == 'ServiceFee'">
					{{ numberWithCommas(Number(props.row.ServiceFee)) }}
				</span>
				<span v-else-if="props.column.field == 'PriceTotal'">
					{{ numberWithCommas(Number(props.row.PriceTotal)) }}
				</span>
				<span v-else-if="props.column.field == 'StatusPayment'">
					<b-badge v-if="props.row.StatusPayment == 'Cancel'" href="#" variant="danger m-2">Cancel</b-badge>
					<b-badge v-else-if="props.row.StatusPayment == 'Menunggu Pembayaran'" href="#" variant="warning m-2">Waiting Payment</b-badge>
					<b-badge v-else href="#" variant="primary m-2">{{ props.row.StatusPayment }}</b-badge>
				</span>
			</template>
		</ktv-table>

		<ktv-modal
			v-model="modalAdvanced"
			title="Advance Filter"
			size="md"
			:centered="true"
			@close="modalAdvanced = false"
		>
			<template #content>
				<b-row>
					<b-col cols="6">
						<b-form-group label="Start Date">
							<b-form-input
								v-model="form.startDate"
								type="date"
								@change="dateChange($event, 'start')"
							/>
						</b-form-group>
					</b-col>
					<b-col cols="6">
						<b-form-group label="End Date">
							<b-form-input
								v-model="form.endDate"
								type="date"
								@change="dateChange($event, 'end')"
							/>
						</b-form-group>
					</b-col>
				</b-row>
			</template>
			<template #footerright>
				<ktv-button
					text="Apply Filter"
					icon="filterwhite"
					color="green-dark"
					@click="applyFilter()"
				/>
			</template>
		</ktv-modal>
	</div>
</template>
<script>
	import { KtvTable, KtvModal, KtvButton } from "@/components"
	export default {
		components: {
			KtvTable,
			KtvModal,
			KtvButton
		},
		props: {
			email: {
				default: "",
				required: true,
				type: String,
			}
		},
		data() {
			return {
				modalAdvanced: false,
				isLoading: false,
				modeTable: "remote",
				columns: [
					{
						label: "Transaction ID",
						field: "TransactionCode",
						thClass: "text-center",
					},
					{
						label: "Date",
						field: "DateCreated",
						thClass: "text-center",
						tdClass: "text-center",
					},
					{
						label: "Buyer Name",
						field: "CustomerName",
						thClass: "text-center",
					},
					{
						label: "Price",
						field: "Price",
						thClass: "text-center",
						tdClass: "text-right",
					},
					{
						label: "Discount",
						field: "Discount",
						thClass: "text-center",
						tdClass: "text-right",
					},
					{
						label: "Admin Fee",
						field: "ServiceFee",
						thClass: "text-center",
						tdClass: "text-right",
					},
					{
						label: "Total",
						field: "PriceTotal",
						thClass: "text-center",
						tdClass: "text-right",
					},
					{
						label: "Payment Method",
						field: "PaymentMethodNameIn",
						thClass: "text-center",
						tdClass: "text-center"
					},
					{
						label: "Status",
						field: "StatusPayment",
						thClass: "text-center",
						tdClass: "text-center"
					}
				],
				rows: [],
				totalRecords: 0,
				serverParams: {
					columnFilters: {
						Email: ""
					},
					sort: [{
						field: "",
						type: ""
					}],
					currentPage: 1,
					rowPerpage: 10,
				},
				form: {
					startDate: null,
					endDate: null
				}
			}
		},
		watch: {
			email: {
				handler(val) {
					if (val !== "") {
						this.updateParams({
							columnFilters: { Email: val },
						})
						this.loadItems()
					}
				},
				deep: true,
				immediate: true,
			},
		},
		// mounted() {
		// 	this.loadItems()
		// },
		methods: {
			dateChange(e, field) {
				if (field === 'start') {
					if (this.form.startDate > this.form.endDate) {
						this.form.endDate = e
					}
				} else if (field === 'end') {
					if (this.form.endDate < this.form.startDate) {
						this.form.endDate = this.form.startDate
					}
				}
			},
			applyFilter() {
				this.updateParams({
					columnFilters: { Email: this.email, startDate: this.form.startDate, endDate: this.form.endDate },
				})
				this.loadItems()
				this.modalAdvanced = false
			},
			openFilter() {
				this.modalAdvanced = true
			},
			numberWithCommas(x) {
				return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			},
			updateParams(newProps) {
				this.serverParams = Object.assign({}, this.serverParams, newProps)
			},
			onPageChange(params) {
				this.updateParams({ currentPage: params.currentPage })
				this.loadItems()
			},
			onPerPageChange(params) {
				this.updateParams({ rowPerpage: params.currentPerPage })
				this.loadItems()
			},
			onSortChange(params) {
				this.updateParams({
					sort: [
						{
							type: params[0].type,
							field: params[0].field,
						},
					],
					currentPage: 1
				})
				this.loadItems()
			},
			onColumnFilter(params) {
				this.updateParams(params)
				this.loadItems()
			},
			onSearchFilter(event) {
				this.updateParams({
					columnFilters: { Name: event },
				})
				this.loadItems()
			},
			loadItems() {
				this.isLoading = true
				this.$http
					.Post(this.serverParams, this.$urlApi.sme.listTransactionSme)
					.then((response) => {
						this.isLoading = false
						if (response.success) {
							this.rows = response.results.data
							this.totalRecords = response.results.total
						} else {
							this.$swal("Error!", response.error_massage, "error")
						}
					})
					.catch((error) => {
						this.isLoading = false
						this.$swal("Error!", error.response.error_massage, "error")
					})
			},
		}
	}
</script>