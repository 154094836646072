<template>
	<div class="main-content">
		<ktv-breadcrumb :title="`${opsiDisplay}` + ' Form'" :page="$t('Registration','Registration')" :folder="$t('SME','SME')" />
		<div>
			<ktv-back @click="backToList()" />
			<b-row class="mt-3">
				<b-col cols="8">
					<ktv-accor :title="$t('Users Data','Users Data')" :footer="opsiDisplay !== 'View'" name="accor-users">
						<template #content>
							<b-tabs active-nav-item-class="nav nav-tabs">
								<b-tab :title="$t('General Data','General Data')" @click="changetab('basicdata')">
									<b-row>
										<b-col>
											<ktv-head-form :title="$t('SME Information','SME Information')" />
											<vcl-list v-show="loading" class="mt-4" />
											<vcl-list v-show="loading" class="mt-4" />
											<b-row v-show="!loading">
												<b-form-group class="col-md-12 mt-2" :label="$t('Commodity','Commodity')">
													<b-form-input v-model="form.CommodityName" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-12 mt-2" :label="$t('SME ID','SME ID')">
													<b-form-input v-model="form.ObjectID" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-12 mt-2" :label="$t('Partner','Partner')">
													<b-form-select
														v-model="form.ObjectPartnerID"
														v-validate="'required'"
														name="Partner"
														:state="validateState('Partner')"
														:options="options.partner"
													/>
													<b-form v-if="(opsiDisplay === 'Update' || opsiDisplay === 'Add')" class="text-danger" style="font-size: 10px">{{ $t('*must be filled.','*must be filled.') }}</b-form>
													<b-form-invalid-feedback>
														{{ veeErrors.first("Partner") }}
													</b-form-invalid-feedback>
												</b-form-group>
												<b-form-group class="col-md-12 mt-2" :label="$t('Institution Name','Institution Name')">
													<b-form-input
														v-model="form.Name"
														v-validate="'required'"
														name="Institution Name"
														:state="validateState('Institution Name')"
														type="text"
													/>
													<b-form v-if="(opsiDisplay === 'Update' || opsiDisplay === 'Add')" class="text-danger" style="font-size: 10px">{{ $t('*must be filled.','*must be filled.') }}</b-form>
													<b-form-invalid-feedback>
														{{ veeErrors.first("Institution Name") }}
													</b-form-invalid-feedback>
												</b-form-group>
												<b-form-group class="col-md-12 mt-2" :label="$t('Year Established','Year Established')">
													<b-form-select
														v-model="form.YearEst"
														name="Year Establised"
														:state="validateState('Year Establised')"
														:options="options.yearEst"
													/>
													<b-form-invalid-feedback>
														{{ veeErrors.first("Year Establised") }}
													</b-form-invalid-feedback>
												</b-form-group>
												<b-form-group class="col-md-12 mt-2" :label="$t('Legal Status of Institution','Legal Status of Institution')">
													<b-form-select
														v-model="form.LegalStatus"
														name="Legal Status"
														:state="validateState('Legal Status')"
														:options="options.legalStatus"
													/>
													<b-form-invalid-feedback>
														{{ veeErrors.first("Legal Status") }}
													</b-form-invalid-feedback>
												</b-form-group>
												<!-- sme role -->
												<b-form-group class="col-md-12 mt-2" :label="$t('SME Role','SME Role')">
													<v-select
														v-model="form.SMECategoryID"
														v-validate="'required'"
														:required="!form.SMECategoryID"
														label="text"
														multiple
														:options="options.smeRole"
														:reduce="(text) => text.value"
														name="SME Role"
														:state="validateState('SME Role')"
													/>
													<b-form v-if="(opsiDisplay === 'Update' || opsiDisplay === 'Add')" class="text-danger" style="font-size: 10px">{{ $t('*must be filled.','*must be filled.') }}</b-form>
													<b-form-invalid-feedback>
														{{ veeErrors.first("SME Role") }}
													</b-form-invalid-feedback>
												</b-form-group>
											</b-row>

											<div class="mt-3" />
											<ktv-head-form title="Location" />
											<vcl-list v-show="loading" class="mt-4" />
											<vcl-list v-show="loading" class="mt-4" />
											<b-row v-show="!loading">
												<b-form-group class="col-md-12 mt-2" :label="$t('Address','Address')">
													<b-form-textarea
														v-model="form.Street"
														name="Address"
														:state="validateState('Address')"
														type="text"
													/>
													<b-form-invalid-feedback>
														{{ veeErrors.first("Address") }}
													</b-form-invalid-feedback>
												</b-form-group>
												<b-form-group class="col-md-12 mt-2" :label="$t('Country','Country')">
													<b-form-select
														v-model="form.EntityCountryID"
														v-validate="'required'"
														name="Country"
														:state="validateState('Country')"
														:options="options.countries"
														@change="changeReg($event, 'country')"
													/>
													<b-form v-if="(opsiDisplay === 'Update' || opsiDisplay === 'Add')" class="text-danger" style="font-size: 10px">{{ $t('*must be filled.','*must be filled.') }}</b-form>
													<b-form-invalid-feedback>
														{{ veeErrors.first("Country") }}
													</b-form-invalid-feedback>
												</b-form-group>
												<b-form-group class="col-md-12 mt-2" :label="$t('Province','Province')">
													<b-form-select
														v-model="form.EntityProvinceID"
														v-validate="'required'"
														name="Province"
														:state="validateState('Province')"
														:options="cmb.province"
														@change="changeReg($event, 'province')"
													/>
													<b-form v-if="(opsiDisplay === 'Update' || opsiDisplay === 'Add')" class="text-danger" style="font-size: 10px">{{ $t('*must be filled.','*must be filled.') }}</b-form>
													<b-form-invalid-feedback>
														{{ veeErrors.first("Province") }}
													</b-form-invalid-feedback>
												</b-form-group>
												<b-form-group class="col-md-12 mt-2" :label="$t('District','District')">
													<b-form-select
														v-model="form.EntityDistrictID"
														v-validate="'required'"
														name="District"
														:state="validateState('District')"
														:options="cmb.district"
														@change="changeReg($event, 'district')"
													/>
													<b-form v-if="(opsiDisplay === 'Update' || opsiDisplay === 'Add')" class="text-danger" style="font-size: 10px">{{ $t('*must be filled.','*must be filled.') }}</b-form>
													<b-form-invalid-feedback>
														{{ veeErrors.first("District") }}
													</b-form-invalid-feedback>
												</b-form-group>
												<b-form-group class="col-md-12 mt-2" :label="$t('Sub District','Sub District')">
													<b-form-select
														v-model="form.EntitySubDistrictID"
														v-validate="'required'"
														name="Sub District"
														:state="validateState('Sub District')"
														:options="cmb.subDistrict"
														@change="changeReg($event, 'subDistrict')"
													/>
													<b-form v-if="(opsiDisplay === 'Update' || opsiDisplay === 'Add')" class="text-danger" style="font-size: 10px">{{ $t('*must be filled.','*must be filled.') }}</b-form>
													<b-form-invalid-feedback>
														{{ veeErrors.first("Sub District") }}
													</b-form-invalid-feedback>
												</b-form-group>
												<b-form-group class="col-md-12 mt-2" :label="$t('Village','Village')">
													<b-form-select
														v-model="form.EntityVillageID"
														v-validate="'required'"
														name="Village"
														:state="validateState('Village')"
														:options="cmb.village"
														@change="changeReg($event, 'village')"
													/>
													<b-form v-if="(opsiDisplay === 'Update' || opsiDisplay === 'Add')" class="text-danger" style="font-size: 10px">{{ $t('*must be filled.','*must be filled.') }}</b-form>
													<b-form-invalid-feedback>
														{{ veeErrors.first("Village") }}
													</b-form-invalid-feedback>
												</b-form-group>
												<!-- <b-form-group class="col-md-10 mt-2" label="Region">
													<b-form-textarea
														v-model="form.RegionInfo"
														v-validate="'required'"
														name="Region"
														:state="validateState('Region')"
														readonly
													/>
													<b-form-invalid-feedback>
														{{ veeErrors.first("Region") }}
													</b-form-invalid-feedback>
												</b-form-group>
												<b-form-group class="col-md-2 mt-2 pl-0" label="">
													<win-region @selected="selectedReg" />
												</b-form-group>
												<b-form-group>
													<b-form-input v-model="tempinput" type="text" hidden />
												</b-form-group> -->
												<b-form-group class="col-md-12 mt-2" :label="$t('Latitude','Latitude')">
													<b-form-input v-model="form.Latitude" type="text" />
												</b-form-group>
												<b-form-group class="col-md-12 mt-2" :label="$t('Longitude','Longitude')">
													<b-form-input v-model="form.Longitude" type="text" />
												</b-form-group>
											</b-row>
										</b-col>
										<b-col>
											<ktv-head-form :title="$t('Business Owner','Business Owner')" />
											<vcl-list v-show="loading" class="mt-4" />
											<b-row v-show="!loading">
												<b-form-group class="col-md-12 mt-2" :label="$t('Owner Name','Owner Name')">
													<b-form-input
														v-model="form.PersonName"
														v-validate="'required'"
														name="Owner Name"
														:state="validateState('Owner Name')"
														type="text"
													/>
													<b-form v-if="(opsiDisplay === 'Update' || opsiDisplay === 'Add')" class="text-danger" style="font-size: 10px">{{ $t('*must be filled.','*must be filled.') }}</b-form>
													<b-form-invalid-feedback>
														{{ veeErrors.first("Owner Name") }}
													</b-form-invalid-feedback>
												</b-form-group>
												<b-form-group class="col-md-12 mt-2" :label="$t('National Identification Number','National Identification Number')">
													<b-form-input
														v-model="form.IdentityNumber"
														v-validate="'min:16|numeric'"
														name="ID Number"
														:state="validateState('ID Number')"
														maxlength="16"
														oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
														type="number"
													/>
													<b-form-invalid-feedback>
														{{ veeErrors.first("ID Number") }}
													</b-form-invalid-feedback>
												</b-form-group>
												<b-form-group class="col-md-12 mt-2" :label="$t('Birth Date','Birth Date')">
													<b-form-input
														v-model="form.BirthDate"
														name="Birth Date"
														:state="validateState('Birth Date')"
														type="date"
													/>
													<b-form-invalid-feedback>
														{{ veeErrors.first("Birth Date") }}
													</b-form-invalid-feedback>
												</b-form-group>
												<b-form-group class="col-md-12 mt-2" :label="$t('Gender','Gender')">
													<b-form-radio-group
														v-model="form.Gender"
														name="Gender"
														:state="validateState('Gender')"
														:options="options.gender"
														class="mt-1"
													/>
													<b-form-invalid-feedback>
														{{ veeErrors.first("Gender") }}
													</b-form-invalid-feedback>
												</b-form-group>
											</b-row>
											<div class="mt-4" />
											<ktv-head-form title="Communication" />
											<vcl-list v-show="loading" class="mt-4" />
											<b-row v-show="!loading">
												<b-form-group class="col-md-12 mt-2" :label="$t('Phone Number','Phone Number')">
													<span class="image-label">(phone number format : (+) + (country code) + phone number)</span>
													<b-form-input
														v-if="(opsiDisplay === 'Update')"
														v-model="form.PhoneNumber"
														v-validate="{ required: true, regex: /^([0-9 +]+)$/ }"
														name="Phone Number"
														:state="validateState('Phone Number')"
														maxlength="15"
														placeholder="+628120000000"
														readonly
													/>
													<b-form-input
														v-else-if="(opsiDisplay !== 'Update')"
														v-model="form.PhoneNumber"
														v-validate="{ required: true, regex: /^([0-9 +]+)$/ }"
														name="Phone Number"
														:state="validateState('Phone Number')"
														maxlength="15"
														placeholder="+628120000000"
													/>
													<b-form v-if="(opsiDisplay === 'Update' || opsiDisplay === 'Add')" class="text-danger" style="font-size: 10px">{{ $t('*must be filled.','*must be filled.') }}</b-form>
													<b-form-invalid-feedback>
														{{ veeErrors.first("Phone Number") }}
													</b-form-invalid-feedback>
												</b-form-group>
												<!--												<b-button>-->
												<!--													Check-->
												<!--												</b-button>-->
												<b-form-group class="col-md-12 mt-2" :label="$t('Email','Email')">
													<b-form-input
														v-if="(opsiDisplay === 'Update')"
														v-model="form.Email"
														v-validate="'email'"
														name="Email"
														:state="validateState('Email')"
														type="email"
														readonly
													/>
													<b-form-input
														v-else-if="(opsiDisplay !== 'Update')"
														v-model="form.Email"
														v-validate="'email'"
														name="Email"
														:state="validateState('Email')"
														type="email"
													/>
													<b-form-invalid-feedback>
														{{ veeErrors.first("Email") }}
													</b-form-invalid-feedback>
												</b-form-group>
												<b-form-group class="col-md-12 mt-2" :label="$t('Last Education','Last Education')">
													<b-form-select v-model="form.EducationType" :options="options.education" />
												</b-form-group>
												<b-form-group class="col-md-12 mt-2" :label="$t('Referral Code','Referral Code')">
													<b-form-input
														v-if="(opsiDisplay === 'Add')"
														v-model="form.ReferralCode"
														v-validate="'required'"
														name="Referral Code"
														:state="validateState('Referral Code')"
														placeholder="referralcode@koltiva.com"
														type="text"
													/>
													<b-form-input
														v-if="(opsiDisplay !== 'Add')"
														v-model="form.ReferralCode"
														name="Referral Code"
														:state="validateState('Referral Code')"
														placeholder="referralcode@koltiva.com"
														type="text"
														readonly
													/>
													<b-form v-if="(opsiDisplay === 'Update' || opsiDisplay === 'Add')" class="text-danger" style="font-size: 10px">{{ $t('*must be filled.','*must be filled.') }}</b-form>
													<b-form-invalid-feedback>
														{{ veeErrors.first("Referal Code") }}
													</b-form-invalid-feedback>
												</b-form-group>
											</b-row>
										</b-col>
									</b-row>
								</b-tab>
								<b-tab :title="$t('Detail Data','Detail Data')" :disabled="disTabDetail" @click="changetab('detaildata')">
									<ktv-head-form title="Bank Information" />
									<vcl-list v-show="loading" class="mt-4" />
									<b-row v-show="!loading">
										<b-form-group class="col-md-12 mt-2" :label="$t('Have any Bank Accounts in the Family?','Have any Bank Accounts in the Family?')">
											<b-form-radio-group v-model="form.isHaveBankAccount" :options="options.yesno" class="mt-1" />
										</b-form-group>
										<b-col>
											<b-row>
												<b-form-group class="col-md-12 mt-2" :label="$t('Name of Account Holder','Name of Account Holder')">
													<b-form-input v-model="formDetail.AccountHolderName" type="text" />
												</b-form-group>
												<b-form-group class="col-md-12 mt-2" :label="$t('Bank Name','Bank Name')">
													<b-form-select v-model="formDetail.BankID" :options="options.bank" />
												</b-form-group>
												<b-form-group class="col-md-12 mt-2" :label="$t('Bank Branch','Bank Branch')">
													<b-form-select v-model="formDetail.BankBranchID" :options="options.bankBranch" />
												</b-form-group>
											</b-row>
										</b-col>
										<b-col>
											<b-row>
												<b-form-group class="col-md-12 mt-2" :label="$t('Account Number','Account Number')">
													<b-form-input v-model="formDetail.AccountNumber" type="number" />
												</b-form-group>
												<b-form-group class="col-md-12 mt-2" :label="$t('Bank Account','Bank Account')">
													<b-form-input v-model="formDetail.BankClientID" type="number" />
												</b-form-group>
												<b-form-group class="col-md-12 mt-2" :label="$t('Account Holder Relation to SME','Account Holder Relation to SME')">
													<b-form-select v-model="formDetail.AccountHolderRelation" :options="options.relation" />
												</b-form-group>
											</b-row>
										</b-col>
									</b-row>
								</b-tab>
							</b-tabs>
						</template>
						<template #footerright>
							<ktv-button
								v-if="(opsiDisplay !== 'View' && opsiDisplay === 'Add')"
								:text="$t('Save','Save')"
								icon="save"
								:loading="loading"
								color="green-dark"
								@click="validationPhone(activetab)"
							/>
							<ktv-button
								v-if="(opsiDisplay === 'Update')"
								:text="$t('Save','Save')"
								icon="save"
								:loading="loading"
								color="green-dark"
								@click="save(activetab)"
							/>
						</template>
					</ktv-accor>
				</b-col>
				<b-col cols="4" />
			</b-row>
			<b-row class="mt-3">
				<b-col cols="8">
					<ktv-accor v-if="opsiDisplay !== 'Add'" :title="$t('Transaction Data','Transaction Data')" name="accor-trans">
						<template #content>
							<b-tabs active-nav-item-class="nav nav-tabs">
								<b-tab :title="$t('Product List','Product List')">
									<list-product :email="form.Email" />
								</b-tab>
								<b-tab :title="$t('Transaction List','Transaction List')">
									<list-trans :email="form.Email" />
								</b-tab>
							</b-tabs>
						</template>
					</ktv-accor>
				</b-col>
			</b-row>
		</div>
	</div>
</template>
<script>
	import ListProduct from "./ListProducts"
	import ListTrans from "./ListTrans"
	import { mapActions, mapGetters } from "vuex"
	import { KtvButton, KtvAccor, KtvHeadForm } from "@/components"

	export default {
		metaInfo: {
			title: "Main Form Registration",
		},
		components: {
			KtvButton,
			KtvAccor,
			KtvHeadForm,
			ListProduct,
			ListTrans
		},
		data() {
			return {
				activetab: "basicdata",
				disTabDetail: false,
				result: [],
				loading: true,
				form: {
					ObjectCommodity: "",
					CommodityName: "",
					EntityID: "",
					ObjectID: "",
					ObjectPartnerID: "",
					Name: "",
					YearEst: "",
					LegalStatus: "",
					SMECategoryID: [],
					Street: "",
					Latitude: "",
					Longitude: "",
					RegionInfo: "",
					EntityCountryID: "",
					EntityProvinceID: "",
					EntityDistrictID: "",
					EntitySubDistrictID: "",
					EntityVillageID: "",
					PersonName: "",
					BirthDate: "",
					Gender: "",
					IdentityNumber: "",
					Email: "",
					PhoneNumber: "",
					EducationType: "",
					ReferralCode: "",
				},
				formDetail: {
					isHaveBankAccount: "",
					AccountHolderName: "",
					BankID: "",
					BankBranchID: "",
					AccountNumber: "",
					BankClientID: "",
					AccountHolderRelation: "",
				},
				cmb: {
					district: [],
					province: [],
					subDistrict: [],
					village: []
				},
				selectedRole: [],
				tempinput: "",
				ValReferral: "",
				warning: "",
			}
		},
		computed: {
			...mapGetters({
				options: "SME/getOptions",
				opsiDisplay: "SME/getOpsiDisplay",
				error: "SME/getError",
			}),
		},
		watch: {
			error(val) {
				if (val != null) {
					this.makeToast("Error", "danger", val.message)
				}
			},
		},
		async mounted() {
			if(this.opsiDisplay === ""){
				this.$router.push('/sme/list'); return
			}
			await this.FetchData()
		},
		methods: {
			changeReg(e, field) {
				if (field === "country") {
					this.cmb.province = [];
					this.cmb.district = [];
					this.cmb.subDistrict = [];
					this.cmb.village = [];
					let param = { CountryID: e }
					this.$http.Get(param, this.$urlApi.sme.getProvince).then((res) => {
						this.cmb.province = res.results
					})
				} else if (field === "province") {
					this.cmb.district = [];
					this.cmb.subDistrict = [];
					this.cmb.village = [];
					let param = { ProvinceID: e }
					this.$http.Get(param, this.$urlApi.sme.getDistrict).then((res) => {
						this.cmb.district = res.results
					})
				} else if (field === "district") {
					this.cmb.subDistrict = [];
					this.cmb.village = [];
					let param = { DistrictID: e }
					this.$http.Get(param, this.$urlApi.sme.getSubDistrict).then((res) => {
						this.cmb.subDistrict = res.results
					})
				} else if (field === "subDistrict") {
					this.cmb.village = [];
					let param = { SubDistrictID: e }
					this.$http.Get(param, this.$urlApi.sme.getVillage).then((res) => {
						this.cmb.village = res.results
					})
				}
			},
			changetab(tab) {
				this.activetab = tab;
			},
			...mapActions({
				clearData: "SME/clearData",
				getDataDetail: "SME/getDataDetail",
				postData: "SME/postData",
				putData: "SME/putData",
			}),
			validationPhone(tab) {
				if (this.form.PhoneNumber !== "") {
					this.$http
						.Get({}, this.$urlApi.sme.getPhoneRegSME + '?PhoneNumber=' + this.form.PhoneNumber)
						.then((r) => {
							if (r.success === false) {
								this.$swal("Error!", r.error_message, "error")
							} else {
								this.$http
									.Get({}, this.$urlApi.sme.getReferralCodeSME + '?ReferralCode=' + this.form.ReferralCode)
									// eslint-disable-next-line no-shadow
									.then((r) => {
										if (r.success === false) {
											this.$swal("Error!", r.error_message, "error")
										}
										this.ValReferral = r
										this.warning = r
										if (this.warning.success === true && this.ValReferral.success === true) {
											this.save(tab)
										}
									})
							}
						})
					if (this.form.SMECategoryID.length === 0) {
						this.$swal("Warning!", "SME role must be filled.", "info");

					}
				} else {
					this.$swal("Warning!", "Phone number must be filled.", "info");
				}
			},
			save(tab) {
				if (tab === 'basicdata') {
					this.$validator.validateAll().then((result) => {
						if (!result) {
							return
						}
						if ((this.form.PhoneNumber).charAt(0) !== "+" ) {
							this.$swal("Warning!", "Phone number format is invalid.", "info");
							return
						}
						if ((this.form.PhoneNumber).charAt(3) === "0" ) {
							this.$swal("Warning!", "Phone number format is invalid. After (+) & (country code) shouldn't (0) ", "info");
							return
						}
						if (this.opsiDisplay === "Update") {
							this.$swal({
								title: "Are you sure?",
								text: "Data will be updated.",
								type: "question",
								showCancelButton: true,
								confirmButtonText: "Submit",
								showLoaderOnConfirm: true,
								preConfirm: () => {
									return this.putData(this.form).then((datares) => {
										return datares
									})
								},
								allowOutsideClick: () => !this.$swal.isLoading(),
							}).then((res) => {
								if (res.value.success) {
									this.$swal("Success!", "Data has been updated.", "success")
								} else {
									this.$swal("Error!", "Data failed to be updated.", "error")
								}
							})
						} else if (this.opsiDisplay === "Add") {
							this.$swal({
								title: "Are you sure?",
								text: "Data will be saved.",
								type: "question",
								showCancelButton: true,
								confirmButtonText: "Submit",
								showLoaderOnConfirm: true,
								preConfirm: () => {
									return this.postData(this.form).then((datares) => {
										return datares
									})
								},
								allowOutsideClick: () => !this.$swal.isLoading(),
							}).then((res) => {
								if (res.value.success) {
									this.$swal("Success!", "Data has been saved.", "success").then(() => {
										this.$router.back()
									})
								} else {
									this.$swal("Error!", "Data failed to be saved.", "error")
								}
							})

						}
					})
				} else if (tab === 'detaildata') {
					// save detail data bank
				}

			},
			selectedReg(e) {
				this.form.EntityVillageID = e.EntityVillageID
				this.form.RegionInfo = e.RegionLabel
				this.tempinput = e.RegionLabel
			},
			async FetchData() {
				if (this.opsiDisplay === "View" || this.opsiDisplay === "Update") {
					await this.getDataDetail().then((res) => {
						this.form = res.basic_data
						this.changeReg(this.form.EntityCountryID, 'country')
						this.changeReg(this.form.EntityProvinceID, 'province')
						this.changeReg(this.form.EntityDistrictID, 'district')
						this.changeReg(this.form.EntitySubDistrictID, 'subDistrict')
					})
					this.loading = false
				} else {
					this.disTabDetail = true
					this.form = {
						ObjectPartnerID: "",
						Name: "",
						YearEst: "",
						LegalStatus: "",
						SMECategoryID: [],
						Street: "",
						Latitude: "",
						Longitude: "",
						RegionInfo: "",
						EntityCountryID: "",
						EntityProvinceID: "",
						EntityDistrictID: "",
						EntitySubDistrictID: "",
						EntityVillageID: "",
						PersonName: "",
						BirthDate: "",
						Gender: "",
						IdentityNumber: "",
						Email: "",
						PhoneNumber: "",
						EducationType: "",
						ReferralCode: "",
					}
					this.loading = false
				}
			},
			backToList() {
				this.clearData()
				this.$router.back()
			},
		},
	}
</script>

<style scoped>
	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type="number"] {
		-moz-appearance: textfield;
	}

	.image-label {
		font-style: italic;
		font-size: 95%;
		color: #ffa500;
	}
</style>
